import {  Link, makeStyles } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles((theme) => ({
    icon:{
        fontSize: '50px'
    },
    root: {
        paddingTop: '5%'
    }
  }));

 export function SocialBar() {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Link href="https://www.instagram.com/meaninglifecoach/">
                <InstagramIcon fontSize='inherit' className={classes.icon} color="secondary" />
            </Link>
            <Link href="https://www.facebook.com/meaninglifecoach">
                <FacebookIcon fontSize='inherit' className={classes.icon}  color="secondary" />
            </Link>
            <Link href="https://twitter.com/meaninglifetime">
                <TwitterIcon fontSize='inherit' className={classes.icon} color="secondary" />
            </Link>
        </div>
    )
}