import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Grid, Link, Hidden } from '@material-ui/core';
import portrait from 'resources/ProfileV3.jpg';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Quote from 'component/quote/Quote';
import {SummaryCopy} from 'Copy/Copy';
import {QuoteCopy} from 'Copy/Copy';
import {SocialBar} from 'component/SocialBar/SocialBar';
import { Banner } from 'component/Banner/Banner';
import { TitleBanner } from 'component/TitleBanner/TitleBanner';
import { Fragment } from 'react';
import { BookButton } from 'component/BookButton/BookButton';
import { Footer } from 'component/Footer/Footer';
import { ProfileBubble } from 'component/ProfileBubble/ProfileBubble';
import { ProfileSummary } from 'component/ProfileSummary/ProfileSummary';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '50px',
    overflowX: 'hidden'
  },
  centerImage: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxHeight: '250px'
  },
  textTitle: {
    fontFamily: 'cocogoose',
    fontWeight: 'bold',
    color: theme.palette.color.primaryDark,
    textAlign: 'center'
  },
  textHandwriting: {
    fontFamily: 'Handwriting',
    textAlign: 'center',
    marginLeft: '10%',
    marginRight: '10%'
  },
  helloText: {
    fontFamily: 'Handwriting',
    color: theme.palette.color.primaryDark
  },
  textLato: {
    fontFamily: 'lato',
    fontSize: '18px'
  },
  summaryText: {
    marginRight: '10%',
    maxWidth: '780px'
  },
  imageContainer: {
    paddingRight: '10%'
  },
  contactName: {
    paddingRight: '20px'
  },
  paddingTop: {
    paddingTop: '50px'
  },
  maxWidth: {
    maxWidth: '780px'
  },
  quote: {
    fontSize: '26px',
    color: theme.palette.color.primaryDark,
    fontFamily: 'cocogoose'
  },
}));


export function HomePage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2} >
        {/* Title Bar */}
        <TitleBanner />

        {/* Summary Bar */}
        <Banner color='white'>
          <Grid container xs ={12} >

            {/* Profile Icon */}
            <Hidden smDown>
              <Grid  container md={5} alignContent='flex-start' direction='row-reverse'>
                <ProfileBubble align={'RIGHT'}/>
              </Grid>
            </Hidden>
            <Hidden mdUp > 
              <Grid  container xs={12} justify='center'>
                <ProfileBubble align={'CENTER'}/>
              </Grid>
            </Hidden>

            {/* Summary info */}
            <Hidden smDown>
              <Grid md={7} alignContent='left' >
                <ProfileSummary align={'LEFT'}/>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item xs={12} justify='center' >
                <ProfileSummary align={'CENTER'}/>
              </Grid>
            </Hidden>

          </Grid>
        </Banner>

        {/* quote Bar */}
        <Banner color='light'>
          <Typography variant="h3" align='center' color='textSecondary'>
            <span className={classes.textHandwriting}>
              WHAT PEOPLE SAY
            </span>
          </Typography>
          {QuoteCopy().map( ({quote, name}) => {
            return <Quote quote={quote} name={name}/>
          })}
        </Banner>

        {/* Lets Chat Bar */}
        <Banner color='white'>
          <Grid >
            <div className={classes.textHandwriting}>
              <span className={classes.quote} >
              TAKE THE FIRST STEP<br />
              BOOK A FREE CONSULTATION<br />
              AND TOGETHER<br />
              WE CAN UNCOVER<br />
              WHAT YOUR NEXT STEPS WILL BE<br />
              </span>
            </div>
          </Grid>
          <Grid item container xs={12} alignContent='center' alignItems='center' justify='center' >
              <Grid item className={classes.paddingTop}>
              <BookButton color='dark' huge/>
              </Grid>
          </Grid>
        </Banner>
        
        {/* Footer */}
        <Footer />
      </Grid>
    </div>
  )
}
