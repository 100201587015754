
import { makeStyles } from '@material-ui/core/styles';
import { Typography, } from '@material-ui/core';
import {SummaryCopy} from 'Copy/Copy';
import { Fragment } from 'react';

const useStyles = makeStyles((theme) => ({
    helloText: {
      fontFamily: 'Handwriting',
      color: theme.palette.color.primaryDark
    },
    textLato: {
      fontFamily: 'lato',
      fontSize: '18px'
    },
    summaryTextLeft: {
      marginRight: '10%',
      maxWidth: '780px'
    },
    summaryTextCenter: {
      marginRight: '10%',
      marginLeft: '10%',
      maxWidth: '780px'
    }
  }));


export function ProfileSummary({align}) {
    const classes = useStyles();

    let alignClass = align === 'LEFT' ? classes.summaryTextRight : classes.summaryTextCenter
    return (
            <div className={alignClass}>
            <Typography variant="h3" align='left'  >
            <span className={classes.helloText}>
                Hi, I'm Marylee
            </span>
            </Typography>
            <Typography align='left' variant='inherit' className={classes.textLato} > {
            SummaryCopy().map(element => {
                return <Fragment>{element} <br /> <br /></Fragment>
            })
            } </Typography>
            </div>
    )
}
