import {  Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    quote: {
      fontSize: '26px',
      color: theme.palette.color.primaryDark
    },
    name: {
        fontSize: '18px',
        color: theme.palette.color.primaryDarker,
        fontWeight: 'bold'
      },
    root: {
        paddingTop: '2%',
        paddingBottom: '2%',
        marginLeft: '10%',
        marginRight: '10%'
    },
  }));

function Quote({quote, name}) {

    const classes = useStyles();

    return (
        <Typography align='center' paragraph className={classes.root}>
            <span className={classes.quote} >"{quote}"</span><br/>
            <span className={classes.name}> {name}</span>
         </Typography>
    )
}


export default Quote