import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Hidden, Menu, MenuItem, Typography } from '@material-ui/core';
import { Banner } from 'component/Banner/Banner';
import { BookButton } from 'component/BookButton/BookButton';
import { useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      paddingBottom: '50px',
      overflowX: 'hidden'
    },
    center: {
        paddingTop: '20px',
        paddingBottom: '20px',
        marginLeft: '10px'
    },
    centerVertical: {
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    button: {
        color: 'white',
        borderColor: 'white',
        '&:hover': {
            borderColor: 'white',
            backgroundColor: 'rgba(255, 255, 255, 0.1)'
          },
    },
    icon: {
        color:'white',
    }
  }));

  const buttons = (classes, size) => {
    return ( <Grid item container xs={size} alignContent='flex-start' direction='row-reverse'>
                <div className={classes.center}>
                    <BookButton />
                </div>
                <div className={classes.center}>
                    <Button variant="text" color="secondary" href="/coaching" className={classes.button} size='large'>
                        <span classname={classes.centerText}>
                            MRE Experience
                        </span>
                    </Button>
                </div>
                <div className={classes.center}>
                    <Button variant="text" color="secondary" href="/" className={classes.button} size='large'>
                        HOME
                    </Button>
                </div>
            </Grid>
    )
}

const buttonsList = (handleClick, handleClose, anchorEl, classes) => {
    return (<Grid item container xs={1} alignContent='flex-start' direction='row-reverse'>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <MenuIcon color="inherit" className={classes.icon}/>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => handleClose("/")}>HOME</MenuItem>
                    <MenuItem onClick={() => handleClose("/coaching")}>MRE EXPERIENCE</MenuItem>
                    <MenuItem onClick={() => handleClose("/schedule-an-appointment")}>BOOK A SESSION</MenuItem>
                </Menu>
            </Grid>
    )
}

export function TitleBanner() {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = (route) => {
      setAnchorEl(null);
      history.push(route)
    };
  
    return (
        <Banner color='dark'>

            {/* Large Size */}
            <Hidden mdDown>
                <Grid container>
                    <Grid item xs={7}>
                        <Typography variant="h2" align='left' color='textSecondary'>
                            MEANING LIFE COACH
                        </Typography>
                    </Grid>
                    {buttons(classes, 5)}
                </Grid>
            </Hidden>

            {/* medium Size */}
            <Hidden only={['xs','sm', 'lg','xl']}>
                <Grid container >
                    <Grid item contianer xs={5} justify='center' direction='column'  alignContent='center' >
                        <div className={classes.centerVertical}>
                            <Typography variant="h4" align='left' color='textSecondary'>
                                MEANING LIFE COACH
                            </Typography>
                        </div>
                    </Grid>
                {buttons(classes, 7)}
                </Grid>
            </Hidden>

            {/* Small Size */}
            <Hidden mdUp>
                <Grid container >
                    <Grid item contianer xs={11} justify='center' direction='column'  alignContent='center' >
                        <Typography variant="h4" align='left' color='textSecondary'>
                            MEANING LIFE COACH
                        </Typography>
                    </Grid>
                {buttonsList(handleClick, handleClose, anchorEl, classes)}
                </Grid>
            </Hidden>

        </Banner>
    )
}

