import './App.css';
import {ThemeProvider } from '@material-ui/core/styles';
import theme from './theme/theme';
import { HomePage } from 'Route/HomePage';
import { ContactMe} from 'Route/ContactMe';
import { Coaching } from 'Route/Coaching';
import {
  Route,
  BrowserRouter,
  Switch
} from 'react-router-dom';

function App() {
  return (
    <BrowserRouter >
      <ThemeProvider theme={theme}>
          <Switch>
            <Route exact path="/" component={HomePage}></Route>           
            <Route path="/schedule-an-appointment" component={ContactMe}></Route>
            <Route path="/coaching" component={Coaching}></Route>    
          </Switch>
      </ThemeProvider>
    </BrowserRouter >
  );
}

export default App;
