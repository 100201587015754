import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#028090'
    },
    secondary: {
      main: '#00A896',
    },
    type: 'dark',
    background:{
      default: '#05668D',
      paper: '#00A896',
    },
    color: {
      primaryLight:'#00A896',
      primaryLighter:'#02C39A',
      primaryDark:'#028090',
      primaryDarker:'#05668D'
    },
    text: {
      primary: '#000000',
      secondary: '#FFFFFF'
    }
  },
  typography: {
    fontFamily: [
      'lato',
      'cocogoose',
      'Handwriting',
      'Arial',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

