import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import portrait from 'resources/ProfileV3.jpg';



const useStyles = makeStyles((theme) => ({
  centerImage: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxHeight: '250px'
  },
  textTitle: {
    fontFamily: 'cocogoose',
    fontWeight: 'bold',
    color: theme.palette.color.primaryDark,
    textAlign: 'center'
  },
  textLato: {
    fontFamily: 'lato',
    fontSize: '18px'
  },
  right: {
    paddingRight: '10%'
  },
  center: {
  }
}));


export function ProfileBubble({align}) {
  const classes = useStyles();

    let alignClass = align === 'RIGHT' ? classes.right : classes.center

    return (
        <div className={alignClass}>
            <img src={portrait} alt="Marylee" className={classes.centerImage} />
            <div className={classes.textTitle}>MARYLEE RUDISILL</div>
            <Typography align='center' variant='body1' className={classes.textLato}>Founder</Typography>
            <Typography align='center' variant='body1' className={classes.textLato}>Meaning Life Coach</Typography>
        </div>
    );
}
