import {  Typography, makeStyles, Paper, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    bannerPaperDark: {
        backgroundColor: theme.palette.color.primaryDarker
      },
      bannerPaperLight: {
        backgroundColor: theme.palette.color.primaryLighter
      },
      bannerPaperWhite: {
        backgroundColor: '#FFFFFF'
      },
      bannerPaper: {
        paddingTop: '5%',
        paddingBottom: '5%',
        paddingLeft: '5%',
        paddingRight: '5%'
      },
  }));

export function Banner({color, children}) {

    const classes = useStyles();

    let pickedColor = 'white'
    switch(color) {
        case 'white': 
        pickedColor = classes.bannerPaperWhite
        break;
        case 'dark': 
        pickedColor = classes.bannerPaperDark
        break;
        case 'light': 
        pickedColor = classes.bannerPaperLight
        break;
    }

    return (
        <Grid xs={12} alignContent='center'>
            <Paper square className={`${classes.bannerPaper} ${pickedColor}`} elevation={0}>
                {children}
            </Paper>
        </Grid>
    )
}

Banner.defaultProps = {
    color: 'white'
}