import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Grid, Link } from '@material-ui/core';
import { TitleBanner } from 'component/TitleBanner/TitleBanner';
import { Banner } from 'component/Banner/Banner';
import { InlineWidget } from 'react-calendly';

const useStyles = makeStyles((theme) => ({
    root: {
      paddingBottom: '50px',
      overflowX: 'hidden'
    }
  }));

export function ContactMe() {
    const classes = useStyles();
  
    return (
        <div className={classes.root}>
            <Grid container spacing={2} >
                <TitleBanner />
                <Banner color='white'> 
                    <InlineWidget url="https://calendly.com/meaninglifecoach?hide_landing_page_details=1" styles={{height: '700px'}}/>
                </Banner>
            </Grid>
        </div>

    )
}