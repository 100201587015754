import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        color: 'white',
        borderColor: 'white',
        paddingLeft: '20px',
        '&:hover': {
            borderColor: 'white',
            backgroundColor: 'rgba(255, 255, 255, 0.1)'
          },
    },
    blueButton: {
        color: theme.palette.color.primaryDark,
        borderColor: theme.palette.color.primaryDark,
        paddingLeft: '20px',
        '&:hover': {
            borderColor: theme.palette.color.primaryDark,
            backgroundColor: 'rgba(2, 128, 144, 0.1)'
          },
    }
  }));

export function BookButton({color, huge}) {
    const classes = useStyles();
  
    let colorClass = classes.button
    switch(color) {
        case 'white': 
        colorClass = classes.button
        break;
        case 'dark': 
        colorClass = classes.blueButton
        break;
    }
    const style = huge ? { width: 200, height: 70 }: {}

    return (
            <Button variant="outlined" color="secondary" href="/schedule-an-appointment" className={colorClass} size="large" style={style}>
                BOOK - A - SESSION
            </Button>
    )
}