import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Grid, Link } from '@material-ui/core';
import { TitleBanner } from 'component/TitleBanner/TitleBanner';
import { Banner } from 'component/Banner/Banner';
import {MethodCopy} from 'Copy/Copy';
import { Fragment } from 'react';
import { Footer } from 'component/Footer/Footer';
import { BookButton } from 'component/BookButton/BookButton';

const useStyles = makeStyles((theme) => ({
    root: {
      paddingBottom: '50px',
      overflowX: 'hidden'
    },
    textHandwriting: {
        fontFamily: 'Handwriting',
        textAlign: 'center',
        marginLeft: '10%',
        marginRight: '10%'
      },
      textLato: {
        fontFamily: 'lato',
        fontSize: '18px',
        textAlign: 'center'
      },
      paddingBottom: {
        paddingBottom: '50px',
      },
      textCoco: {
        fontSize: '26px',
        fontFamily: 'cocogoose',
        fontWeight: 'bold'
      },
  }));

export function Coaching() {
    const classes = useStyles();
  
    return (
        <div className={classes.root}>
            <Grid container spacing={2} >
                <TitleBanner />
                <Banner color='white'> 
                    <div className={classes.paddingBottom}>
                        <Typography variant="h3" align='center' color='textPrimary'>
                            <span className={classes.textCoco}>
                                MRE GUIDED EXPERIENCE
                            </span>
                        </Typography>
                    </div>
                    <Typography align='center' variant='inherit' className={classes.textLato} >
                        {
                            MethodCopy().map(element => {
                                return <div>{element} <br /> <br /></div>
                            })
                        } 
                    </Typography>

                    <Grid item container xs={12} alignContent='center' alignItems='center' justify='center' >
                        <Grid item className={classes.paddingTop}>
                        <BookButton color='dark' huge/>
                        </Grid>
                    </Grid>
                </Banner>
                <Footer />
            </Grid>
        </div>
    )
}