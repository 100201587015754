import { Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import {SocialBar} from 'component/SocialBar/SocialBar';
import { Banner } from 'component/Banner/Banner';
import { BookButton } from 'component/BookButton/BookButton';

const useStyles = makeStyles((theme) => ({
    root: {
      paddingBottom: '50px',
      overflowX: 'hidden'
    },
    centerImage: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxHeight: '250px'
    },
    textTitle: {
      fontFamily: 'cocogoose',
      fontWeight: 'bold',
      color: theme.palette.color.primaryDark,
      textAlign: 'center'
    },
    textHandwriting: {
      fontFamily: 'Handwriting',
      textAlign: 'center',
      marginLeft: '10%',
      marginRight: '10%'
    },
    helloText: {
      fontFamily: 'Handwriting',
      color: theme.palette.color.primaryDark
    },
    textLato: {
      fontFamily: 'lato',
      fontSize: '18px'
    },
    summaryText: {
      marginRight: '10%',
      maxWidth: '780px'
    },
    imageContainer: {
      paddingRight: '10%'
    },
    contactName: {
      paddingRight: '20px'
    },
    paddingTop: {
      paddingTop: '50px'
    },
    maxWidth: {
      maxWidth: '780px'
    }
  }));
  
  export function Footer() {
    const classes = useStyles();
  
    return (
        <Fragment>
            {/* contact info Bar */}
            <Banner color='dark'>
                <Grid  item xs={12}>
                    {/* <Grid container  item xs={12}>
                        <Grid item xs={6} >
                            <Typography variant="h5" align='right' className={classes.contactName}>MARYLEE RUDISILL</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="h5" align='left' >
                                <PhoneIcon /> 
                                {" "}512.705.9040
                            </Typography>
                            <Typography variant="h5" align='left' >
                                <EmailIcon />
                                {" "} hi@meaninglifecoach.com
                            </Typography>
                        </Grid>
                    </Grid> */}
                    <Grid item container xs={12} alignContent='center' alignItems='center' justify='center'>
                        <SocialBar />
                    </Grid>
                </Grid>
            </Banner>
        </Fragment>
    );
  }

        