


const SummaryCopy = () => { 
    return [
        "This past year and a half, as our post Covid world emerges I see a lot of struggle within people trying to define what this new world is and how they fit.        Whether facing socializing differently, reintegrating back into the office, transitioning to a new career, moving on from relationships and friendships and more. Let’s discuss in further, together how these moments make you feel and what you are willing to do or be.",
        "Life is hard, with so much pressure and endless deadlines, things build up and leave us feeling spread too thin, anxious and wanting to escape it all.  It can be difficult to see that how we have coped with this, getting by barely day to day, can build up and one day we erupt.  Overtime, the weight becomes so heavy, we allow life to pass us by running on this continuous hamster wheel. ",
        "This is why a good life coach is so important. Working through the daily things, helping to limit what’s holding you back, defining a clear vision,  not getting side tracked by time wasters and bad habits, making a work/life balance, together we can work through all of these things that may apply.  We can work through ways to allow a growth mindset, focusing on who you are now and where you want to take your future.",
        "So, where do we go from here? I am here to listen, to help you uncover and discover where you want to be.  Let’s work together, book your first call with me. Let’s partner. Our first one-hour call will be free, it’s up to YOU."
    ]
}

const QuoteCopy = () => {
    return [
        {
            quote: "Marylee changed my life",
            name: "Chris M"
        },
        {
            quote: "The transformation I had after reaching major breakthroughs, priceless",
            name: "Elizabeth"
        }
    ]
} 

const MethodCopy = () => { 
    const style = {
        fontWeight: 'bold'
      };
    return [
        (<div>
            <span style={style}>A 6 week ONE ON ONE experience to</span> <br/>
            help you master the MRE Model and apply it on your journey.<br/>
             Includes 6 1-hr sessions with homework you'll enjoy!<br/>
        </div>),
        (<div>
            <span style={style}>1. Learn the model - WEEK 1 -</span> The 3 core energies<br/>
            are manifested in people and the world.<br/>
            Learn what they are and how to spot them<br/>
            around you, developing a new superpower.<br/>
         </div>),
         (<div>
             <span style={style}>2. Locate your core energy - WEEK 2 - </span><br/>
             Unpack your core energy and <br/>
             gain a deep understanding of yourself, <br/>
             what drives you and how you <br/>
             express that energy in the world.<br/>
        </div>),
        (<div>
            <span style={style}>3. Apply MRE - WEEK 3 to 6 -</span> <br/>
            Align yourself in your work, relationships <br/>
            and an in depth discovery of how <br/>
            to complete your MRE Equation.<br/>
        </div>),
    ]
}













export { SummaryCopy, QuoteCopy, MethodCopy }